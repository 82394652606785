import React from 'react'
import PageTemplate from '@components//pageTemplate'

let About = () => (
  <PageTemplate title="About Us">
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo erat eu viverra mollis. Quisque tellus ante, commodo nec tellus sit amet, ornare blandit justo. Integer in cursus justo, vel aliquam ex. Etiam ligula sapien, sagittis eu posuere et, tincidunt a arcu. Nullam egestas lacus a molestie efficitur. Fusce neque mauris, commodo at lectus ut, aliquet pretium neque. Cras laoreet sapien lorem, eu auctor lorem porta quis. Proin placerat, nunc quis placerat mattis, ligula velit mollis nisi, ac maximus velit urna vel nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo erat eu viverra mollis. Quisque tellus ante, commodo nec tellus sit amet, ornare blandit justo. Integer in cursus justo, vel aliquam ex. Etiam ligula sapien, sagittis eu posuere et, tincidunt a arcu. Nullam egestas lacus a molestie efficitur. Fusce neque mauris, commodo at lectus ut, aliquet pretium neque. Cras laoreet sapien lorem, eu auctor lorem porta quis. Proin placerat, nunc quis placerat mattis, ligula velit mollis nisi, ac maximus velit urna vel nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo erat eu viverra mollis. Quisque tellus ante, commodo nec tellus sit amet, ornare blandit justo. Integer in cursus justo, vel aliquam ex. Etiam ligula sapien, sagittis eu posuere et, tincidunt a arcu. Nullam egestas lacus a molestie efficitur. Fusce neque mauris, commodo at lectus ut, aliquet pretium neque. Cras laoreet sapien lorem, eu auctor lorem porta quis. Proin placerat, nunc quis placerat mattis, ligula velit mollis nisi, ac maximus velit urna vel nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
  </PageTemplate>
)

export default About
